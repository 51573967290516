<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Dados cadastrais</span>

      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.strCbo"
          name="strCbo"
          label="CBO"
          required
          rules="required"
          v-mask="'9999-99'"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          v-model="formData.strNome"
          name="strNome"
          label="Nome"
          required
          rules="required"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strDescricao"
          name="strDescricao"
          label="Descrição da Função"
          required
          rules="required"
          :disabled="!formData.boolAtivo"
        />

        <ed-input-switch
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.boolAtivo"
          name="boolAtivo"
          label="Função ativa?"
          required
          rules="required"
        />
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <campos-personalizado
        :formData="formData"
        :key="'campo-' + $root.$session.versao"
        :intTipoModeloId="310"
        @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
      />
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
} from "@/components/common/form";

import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";
export default {
  name: "pageCadastroFuncao",
  props: {
    intFuncaoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputSelect,
    EdInputTextArea,
    EdInputSwitch,
    CamposPersonalizado,
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
